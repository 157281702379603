import moment from "moment";

//   /*************************************************************************************
//                           Date format from unix
//    **************************************************************************************/
export const formatDate = (date) => {
  // let newDate = moment.unix(date / 1000).format("MM/DD/YYYY");
  let newDate = moment(date).format("MMMM YYYY");
  return newDate;
};
//   /*************************************************************************************/